<template>
  <div>
    <RevokeTask :operRole="1" />
  </div>
</template>
<script>
import RevokeTask from '../common/RevokeTask.vue'
export default {
  components: {
    RevokeTask
  }
}
</script>
